<template>
  <div>
    <booking-main-info :booking="booking" with-orders-btn/>

    <div v-if="services.length">
      <b-row>
        <b-col v-for="service in services" :key="service.id" md="3">
          <b-card
              class="card-custom gutter-b card-stretch bg-hover-light cursor-pointer"
              :class="{'bg-light-success': selected.find(el => el.id === service.id)}"
              @click="toggleSelected(service.id)"
          >
            <template #default>
              <div class="text-center font-size-h6 font-weight-bold">{{ service.name }}</div>
              <div class="text-center mt-2">{{ `${service.price} ${service.currency}` }}</div>
              <div class="text-center text-muted mt-2">{{ service.description }}</div>
            </template>
          </b-card>
        </b-col>
      </b-row>

      <b-button class="d-flex m-auto px-15" :class="{disabled: !selected.length}" variant="success"
                v-b-modal.servicesCart>
        {{ $t('btn.order') }}
      </b-button>
    </div>
    <div v-else class="text-center text-dark-75 font-size-h4 mt-10">{{ $t('description.list_is_empty') }}</div>

    <b-modal id="servicesCart" size="lg" hide-header hide-footer centered>
      <div v-for="item in selected" :key="item.id" class="mb-3">
        <b-row class="align-items-center">
          <b-col md="5" class="font-size-h5 font-weight-bolder">{{ item.name }}</b-col>
          <b-col md="3">
            <DatetimePickerFormGroup
                class="mb-0"
                :value="item.datetime"
                mode="dateTime"
                :min-datetime="booking.income_original_date"
                :max-datetime="booking.exit_original_date"
                :placeholder="$t('placeholder.choose_date_and_time')"
                format="YYYY-MM-DDTHH:mm:ss.SSSZ"
                required
                @change="item.datetime = $event"
            />
          </b-col>
          <b-col md="2" class="d-flex justify-content-end align-items-center">
            <span
                class="far fa-minus-circle font-size-h4 mr-2"
                :class="{'opacity-40': !item.quantity}"
                @click="item.quantity ? item.quantity-- : null"
            />
            <span class="item-quantity font-size-h4">{{ item.quantity }}</span>
            <span class="far fa-plus-circle font-size-h4 ml-2" @click="item.quantity++"></span>
          </b-col>
          <b-col md="2" class="d-flex justify-content-end align-items-center text-danger">
            <span class="far fa-times" @click="deleteItem(item)"></span>
          </b-col>
        </b-row>
      </div>
      <b-button class="d-flex mx-auto mt-10" variant="light-success" @click="submit">{{ $t('btn.submit') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';
import BookingMainInfo from '@/components/bookings/BookingMainInfo';

export default {
  components: {
    DatetimePickerFormGroup,
    BookingMainInfo
  },
  data: () => ({
    bookingId: null,
    selected: []
  }),
  async beforeMount() {
    this.bookingId = this.$route.query.booking_id;
    if (!this.bookingId) {
      this.$router.push({name: 'bookingsList'});
    }
    await this.$store.dispatch('bookingsStore/GET_DETAILS', this.bookingId);
    this.$store.dispatch('servicesStore/GET_SERVICES', this.bookingId);
  },
  computed: {
    ...mapGetters({
      services: 'servicesStore/SERVICES',
      booking: 'bookingsStore/DETAILS'
    }),
    selectedServices() {
      return this.services.filter(el => this.selected.map(el => el.id).includes(el.id)).map(item => {
        return {...item, quantity: 1};
      });
    }
  },
  methods: {
    toggleSelected(serviceId) {
      const selectedItem = this.selected.find(el => el.id === serviceId);
      if (selectedItem) {
        this.selected = this.selected.filter(el => el.id !== serviceId);
      } else {
        this.$set(this.selected, this.selected.length, {
          id: serviceId,
          quantity: 1,
          name: this.services.find(el => el.id === serviceId).name,
          datetime: this.booking.income_original_date
        });
      }
    },
    deleteItem(item) {
      this.selected = this.selected.filter(el => el.id !== item.id);

      if (!this.selected.length) {
        this.$bvModal.hide('servicesCart');
      }
    },
    submit() {
      this.$store.dispatch('servicesStore/ORDER', {
        services: this.selected, bookingId: this.bookingId
      }).then(_ => {
        this.$router.push({name: 'bookingOrders', params: {bookingId: this.bookingId}});
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-quantity {
  flex: 0 0 25px;
  text-align: center;
}
</style>